<template>
  <div>
    <h3 class="page-title">ئېتىبار باشقۇرۇش</h3>
    <el-divider></el-divider>
    <el-row class="rtl-right">
      <el-button type="primary" plain icon="el-icon-circle-plus-outline" v-if="pageTags.create" @click="add">ئېتىبار قوشۇش</el-button>
    </el-row>
    <div class="rtl-right search-box">
      <el-row :gutter="10">
        <el-col :span="5">
          <el-select v-model="filterForm.priceable_type" class="rtl-right" @change="filterTypeChange()" filterable placeholder="تۈرى">
            <el-option
              v-for="item in ownerableTypeList"
              :key="item.name"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <p class="el-input__inner" @click="showDrawer()">
            <label v-if="filterForm.obj_name!=''&&filterForm.obj_name!='-'">{{filterForm.obj_name}}</label>
            <label v-if="filterForm.obj_name==''" style="color: #C0C4CC;font-size: 14px;">ئوبيېكت  تاللاڭ </label>
          </p>
        </el-col>
        <el-col :span="4">
          <el-button type="success" plain icon="el-icon-search" @click="getList()">ئىزدەش </el-button>
          <el-button type="danger" plain icon="el-icon-circle-close" @click="toClear()">بىكار  قىلىش </el-button>
        </el-col>
      </el-row>
    </div>

    <el-table :data="tableData" border v-loading="loading" style="width: 100%">
      <el-table-column prop="discountable" label="ئىسمى">
        <template slot-scope="scope">
          <label v-if="scope.row.discountable.name_ug != '' && scope.row.discountable.name_ug != '-'">{{scope.row.discountable.name_ug}}</label>
          <label v-if="scope.row.discountable.name_ug == '' || scope.row.discountable.name_ug == '-'">{{scope.row.vip.teacher.name_ug}}</label>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="ئېتىبار باھاسى" width="120"></el-table-column>
      <el-table-column prop="originPrice.price" label="بۇرۇنقى باھاسى" width="120"></el-table-column>
      <el-table-column prop="originPrice.term.name_ug" label="مۇددەت" width="120"></el-table-column>
      <el-table-column prop="start_time" label="باشلىنىش ۋاقتى"></el-table-column>
      <el-table-column prop="end_time" label="ئاخىرلىشىش ۋاقتى"></el-table-column>
      <el-table-column label="مەشغۇلات" prop="dosome" width="300">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">تەھرىرلەش </el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">ئۆچۈرۈش </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      background
      :current-page="current_page"
      :page-sizes="[10, 20, 30, 40, 50, 100]"
      layout="sizes,prev, pager, next,total,jumper"
      :total="total">
    </el-pagination>

    <!-- vips -->
    <el-drawer
      title="ئالاھىدە ئەزا تاللاش"
      size="50%"
      :visible.sync="vipsDrawer"
      direction="rtl">
      <div class="drawer-table-box" v-show="filterDrawer">
        <el-table :data="vipTableData" border style="width: 100%">
          <el-table-column prop="teacher.name_ug" label="ئوقۇتقۇچى"></el-table-column>
          <el-table-column prop="name_ug" label="ئالاھىدە ئەزا (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label="ئالاھىدە ئەزا (خەنزۇچە)"></el-table-column>
          <el-table-column label="مەشغۇلات">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="confirmChoiseVip(scope.row)">تاللاشنى مۇقىملاش</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="drawer-table-box" v-show="!filterDrawer">
        <el-table :data="vipTableData" border style="width: 100%">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table :data="props.row.prices" border style="width: 100%">
                <el-table-column prop="term.name_ug" label="مۇددەت"></el-table-column>
                <el-table-column prop="term.days" label="كۈن"></el-table-column>
                <el-table-column prop="price" label="باھا"></el-table-column>
                <el-table-column label="مەشغۇلات">
                  <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="confirmChoiseVip(scope.row)">تاللاشنى مۇقىملاش</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="teacher.name_ug" label="ئوقۇتقۇچى"></el-table-column>
          <el-table-column prop="name_ug" label="ئالاھىدە ئەزا (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label="ئالاھىدە ئەزا (خەنزۇچە)"></el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <!-- agent -->
    <el-drawer
      title="ۋاكالەتچى تاللاش"
      size="50%"
      :visible.sync="agentsDrawer"
      direction="rtl">
      <div class="drawer-table-box" v-show="filterDrawer">
        <el-table :data="agentTableData" border style="width: 100%">
          <el-table-column prop="name_ug" label="ئالاھىدە ئەزا (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label="ئالاھىدە ئەزا (خەنزۇچە)"></el-table-column>
          <el-table-column label="مەشغۇلات">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="confirmChoiseAgent(scope.row)">تاللاشنى مۇقىملاش</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChangeAgent"
          @current-change="handleCurrentChangeAgent"
          background
          :current-page="agent_current_page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="sizes,prev, pager, next,total,jumper"
          :total="agent_total">
        </el-pagination>
      </div>
      <div class="drawer-table-box" v-show="!filterDrawer">
        <el-table :data="agentTableData" border style="width: 100%">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table :data="props.row.prices" border style="width: 100%">
                <el-table-column prop="term.name_ug" label="مۇددەت"></el-table-column>
                <el-table-column prop="term.days" label="كۈن"></el-table-column>
                <el-table-column prop="price" label="باھا"></el-table-column>
                <el-table-column label="مەشغۇلات">
                  <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="confirmChoiseAgent(scope.row)">تاللاشنى مۇقىملاش</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="name_ug" label="ئالاھىدە ئەزا (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label="ئالاھىدە ئەزا (خەنزۇچە)"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChangeAgent"
          @current-change="handleCurrentChangeAgent"
          background
          :current-page="agent_current_page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="sizes,prev, pager, next,total,jumper"
          :total="agent_total">
        </el-pagination>
      </div>
    </el-drawer>

    <!-- course -->
    <el-drawer
      title="دەرس تاللاش"
      size="70%"
      :visible.sync="coursesDrawer"
      direction="rtl">
      <div class="drawer-table-box" v-show="filterDrawer">
        <el-table :data="courseTableData" border style="width: 100%">
          <el-table-column prop="name_ug" label=" دەرس (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label=" دەرس (خەنزۇچە)"></el-table-column>
          <el-table-column label="مەشغۇلات">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="confirmChoiseCourse(scope.row)">تاللاشنى مۇقىملاش</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChangeCourse"
          @current-change="handleCurrentChangeCourse"
          background
          :current-page="course_current_page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="sizes,prev, pager, next,total,jumper"
          :total="course_total">
        </el-pagination>
      </div>
      <div class="drawer-table-box" v-show="!filterDrawer">
        <el-table :data="courseTableData" border style="width: 100%">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table :data="props.row.prices" border style="width: 100%">
                <el-table-column prop="term.name_ug" label="مۇددەت"></el-table-column>
                <el-table-column prop="term.days" label="كۈن"></el-table-column>
                <el-table-column prop="price" label="باھا"></el-table-column>
                <el-table-column label="مەشغۇلات">
                  <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="confirmChoiseCourse(scope.row)">تاللاشنى مۇقىملاش</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="name_ug" label=" دەرس (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label=" دەرس (خەنزۇچە)"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChangeCourse"
          @current-change="handleCurrentChangeCourse"
          background
          :current-page="course_current_page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="sizes,prev, pager, next,total,jumper"
          :total="course_total">
        </el-pagination>
      </div>
    </el-drawer>

    <!-- discount manage -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose">
      <div class="rtl-right form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">

          <el-form-item label="تۈرى" prop="discountable_type">
            <el-row>
              <el-col :span="8">
                <label class="choise-tip" v-if="ruleForm.discountable_type == ''">(ئاۋۋال تۈر تاللاڭ)</label>
                <el-button type="success" plain v-if="ruleForm.discountable_type == 'vips'" @click="showVips">ئالاھىدە ئەزا تاللاش</el-button>
                <el-button type="success" plain v-if="ruleForm.discountable_type == 'agents'" @click="showAgents">ۋاكالەتچى تاللاش</el-button>
                <el-button type="success" plain v-if="ruleForm.discountable_type == 'courses'" @click="showCourses">دەرس تاللاش</el-button>
              </el-col>
              <el-col :span="16">
                <el-select v-model="ruleForm.discountable_type" class="rtl-right" filterable clearable placeholder="تۈرى">
                  <el-option
                    v-for="item in ownerableTypeList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="تاللىغان ئالاھىدە ئەزا " v-if="ruleForm.discountable_type == 'vips'">
            <p class="cur-text" v-if="curVipPrice">
              <label v-if="curVipPrice.priceable.name_ug&&curVipPrice.priceable.name_ug!='-'">{{curVipPrice.priceable.name_ug}}</label>
              <label v-if="curVipPrice.priceable.teacher_id">{{curVipPrice.priceable.teacher_id | filterTeacher}}</label>
              ({{curVipPrice.term.name_ug}}-{{curVipPrice.price}} يۈەن)
            </p>
          </el-form-item>
          <el-form-item label="تاللىغان ۋاكالەتچى" v-if="ruleForm.discountable_type == 'agents'">
            <p class="cur-text" v-if="curAgentPrice">{{curAgentPrice.priceable.name_ug}} ({{curAgentPrice.term.name_ug}}-{{curAgentPrice.price}} يۈەن)</p>
          </el-form-item>
          <el-form-item label="تاللىغان دەرس" v-if="ruleForm.discountable_type == 'courses'">
            <p class="cur-text" v-if="curCoursePrice">{{curCoursePrice.priceable.name_ug}} ({{curCoursePrice.term.name_ug}}-{{curCoursePrice.price}} يۈەن)</p>
          </el-form-item>

          <el-form-item label="ئېتىبار باھاسى" prop="price">
            <el-input v-model="ruleForm.price" class="ltr-left" placeholder="ئېتىبار باھاسى"></el-input>
          </el-form-item>
          <el-form-item label="ۋاقىت دائىرىسى" prop="timeRange">
            <el-date-picker
              style="width:100%"
              class="ltr-left"
              v-model="ruleForm.timeRange"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="-"
              unlink-panels
              start-placeholder="باشلىنىش ۋاقتى"
              end-placeholder="ئاخىرلىشىش ۋاقتى">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="ھالەت" prop="status" class="radio-form">
            <el-radio v-model="ruleForm.status" label="1">ئوچۇق</el-radio>
            <el-radio v-model="ruleForm.status" label="0">ئېتىك</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
        <el-button type="warning" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="closeDialog('ruleForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<style lang="scss" scoped>
  .table-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #EBEEF5;
  }
  .el-date-editor{
    width: 100%;
  }
  .choise-tip{
    position: relative;
    top: 10px;
    right: 10px;
    color: #E6A23C;
  }
  .drawer-table-box{
    padding: 0 10px;
  }
  .cur-text{
    position: relative;
    top: 10px;
    color: #409EFF;
  }
  .search-box{
    margin-bottom: 10px;
    .el-select{
      width: 100%;
    }
    [class*="el-col-"] {
      float: right;
    }
  }
  .el-drawer.rtl{
    overflow-y: auto;
  }
</style>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
      self.getTeachersList();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        per_page:10,
        current_page:1,
        last_page:0,
        total:0,
        pageTags:{},
        editId:"",
        dialogVisible:false,
        ruleForm:{
          discountable_type:'',
          timeRange:[],
          price:"",
          status:"1"
        },
        curUser:{},
        ownerableTypeList:[
          {
            name:"ئالاھىدە ئەزا",
            value:"vips"
          },
          {
            name:"ۋاكالەتچى",
            value:"agents"
          },
          {
            name:"دەرس",
            value:"courses"
          }
        ],
        curVipPrice:null,
        curAgentPrice:null,
        curCoursePrice:null,
        vipsDrawer:false,
        vipTableData:[],
        agentsDrawer:false,
        agentTableData:[],
        agent_per_page:10,
        agent_current_page:1,
        agent_last_page:0,
        agent_total:0,
        coursesDrawer:false,
        courseTableData:[],
        course_per_page:10,
        course_current_page:1,
        course_last_page:0,
        course_total:0,
        filterForm:{
          priceable_type:"",
          obj_name:"",
          priceable_id:""
        },
        filterDrawer:false,
        curItem:{},
        showList:true,
        addDialog:true,
        dialogTitle:"",
        editId:"",
        teachersData:[]
      };
    },
    methods: {
      getList(){
        self.loading = true;
        self.$fetch("admin/discount",{
          page : self.current_page,
          per_page : self.per_page,
          discountable_type:self.filterForm.priceable_type,
          discountable_id:self.filterForm.priceable_id
        }).then(response => {
          if (response.status == 200) {
           self.tableData = response.data.data;
           self.total = response.data.meta.total;
           self.current_page = response.data.meta.current_page;
           self.last_page = response.data.meta.last_page;
          } else{
            console.log(response.message);
          }
          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      handleSizeChange(val){
        self.per_page = val;
        self.getList();
      },
      handleCurrentChange(val){
        self.current_page = val;
        self.getList();
      },
      add(){
        self.addDialog = true;
        self.dialogTitle = "ئېتىبار قوشۇش";
        self.dialogVisible = true;
        self.filterDrawer = false;
      },
      getTeachersList() {
        self.$fetch("admin/teacher").then(response => {
          if (response.status == 200) {
            self.teachersData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      toClear(){
        // 1.clear other option 
        self.filterForm = {
          priceable_type:"",
          obj_name:"",
          priceable_id:""
        }
        self.tableData = [];
        self.current_page = 1;
        // 2.getList
        self.getList();
      },
      resetForm(formName){
        self.$refs[formName].resetFields();
        self.ruleForm = {
          discountable_type:'',
          timeRange:[],
          price:"",
          status:"1"
        }
        self.curCoursePrice = null;
        self.curAgentPrice = null;
        self.curVipPrice = null;
      },
      handleEdit(index, row) {
        // discountable_type:self.curItem.priceable_type,
        // discountable_id:self.curItem.priceable_id,
        // price_id:self.curItem.id,
        // price:self.ruleForm.price,
        // start_time:self.ruleForm.timeRange[0],
        // end_time:self.ruleForm.timeRange[1],
        // status: self.ruleForm.status

        console.log(row);
        self.addDialog = false;
        self.dialogTitle = "ئېتىبار تەھرىرلەش";
        self.editId = row.id;
        self.ruleForm.status = row.status + '';
        var tmpArr = [];
        tmpArr[0] = row.start_time;
        tmpArr[1] = row.end_time;
        self.ruleForm.timeRange = tmpArr;
        self.ruleForm.price = row.price;
        self.ruleForm.discountable_type = row.discountable_type;

        self.curItem.priceable_type = row.discountable_type;
        self.curItem.priceable_id = row.discountable_id;
        self.curItem.id = row.price_id;

        self.dialogVisible = true;
      },
      getVipsList(){
        self.$fetch("admin/vip").then(response => {
          if (response.status == 200) {
            self.vipTableData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      getAgentsList(){
        self.$fetch("admin/agent",{
          page : self.agent_current_page,
          per_page : self.agent_per_page
        }).then(response => {
          if (response.status == 200) {
           self.agentTableData = response.data.data;
           self.agent_total = response.data.meta.total;
           self.agent_current_page = response.data.meta.current_page;
           self.agent_last_page = response.data.meta.last_page;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      getCoursesList(){
        self.$fetch("admin/course",{
          page : self.course_current_page,
          per_page : self.course_per_page
        }).then(response => {
          if (response.status == 200) {
           self.courseTableData = response.data.data;
           self.course_total = response.data.meta.total;
           self.course_current_page = response.data.meta.current_page;
           self.course_last_page = response.data.meta.last_page;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      showDrawer(){
        self.filterDrawer = true;
        switch (self.filterForm.priceable_type) {
          case 'vips':
            self.showVips();
            break;
          case 'agents':
            self.showAgents();
            break;
          case 'courses':
            self.showCourses();
            break;
        } 
      },
      showVips(){
        self.getVipsList();
        self.vipsDrawer = true;
      },
      confirmChoiseVip(row){
        console.log(row);
        if(self.filterDrawer){
          self.filterForm.priceable_id = row.id;
          if(row.name_ug != '' && row.name_ug != '-'){
            self.filterForm.obj_name = row.name_ug;
          }else{
            self.filterForm.obj_name = row.teacher.name_ug;
          }
        }else{
          self.curVipPrice = row;
          self.curItem = row;
        }
        self.vipsDrawer = false;
      },
      showAgents(){
        self.getAgentsList();
        self.agentsDrawer = true;
      },
      confirmChoiseAgent(row){
        if(self.filterDrawer){
          self.filterForm.priceable_id = row.id;
          self.filterForm.obj_name = row.name_ug;
        }else{
          self.curAgentPrice = row;
          self.curItem = row;
        }
        self.agentsDrawer = false;
      },
      handleSizeChangeAgent(val){
        self.agent_per_page = val;
        self.getAgentsList();
      },
      handleCurrentChangeAgent(val){
        self.agent_current_page = val;
        self.getAgentsList();
      },
      showCourses(){
        self.getCoursesList();
        self.coursesDrawer = true;
      },
      confirmChoiseCourse(row){
        console.log(row);
        if(self.filterDrawer){
          self.filterForm.priceable_id = row.id;
          self.filterForm.obj_name = row.name_ug;
        }else{
          self.curCoursePrice = row;
          self.curItem = row;
        }
        self.coursesDrawer = false;
      },
      handleSizeChangeCourse(val){
        self.course_per_page = val;
        self.getCoursesList();
      },
      handleCurrentChangeCourse(val){
        self.course_current_page = val;
        self.getCoursesList();
      },
      filterTypeChange(){
        self.filterForm.obj_name = "";
        self.filterForm.priceable_id = "";
        self.current_page = 1;
      },
      submitForm(ruleForm){
        var myData = {
          discountable_type:self.curItem.priceable_type,
          discountable_id:self.curItem.priceable_id,
          price_id:self.curItem.id,
          price:self.ruleForm.price,
          start_time:self.ruleForm.timeRange[0],
          end_time:self.ruleForm.timeRange[1],
          status: self.ruleForm.status
        }

        if(self.addDialog){
          self.$post('admin/discount',myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(ruleForm);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response.data.message);
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }else{
          self.$put('admin/discount/'+self.editId,myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(ruleForm);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500
              });
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }
      },
      handleClose(done) {
        done();
        self.resetForm("ruleForm");
      },
      closeDialog(ruleForm){
        self.resetForm(ruleForm);
        self.dialogVisible = false;
      },
      handleDelete(index,row){
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/discount/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      }
    },
    filters:{
      filterTeacher:function(id){
        var teacher_name = "";
        self.teachersData.forEach(function(item,index){
          if(item.id == id){
            teacher_name = item.name_ug;
          }
        });
        return teacher_name;
      }
    }
  };
</script>
